<script setup>
import {computed, onMounted, ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";
const store = useStore()
// запрос на role делаем каждый раз, не только при авторизации
const userData = ref([])

onMounted( ()=>{
  try {
    init()
  } catch (err) {
    alert ('APPLICATION ERROR')
  }
})

const init = async () => {
  const urlData =  process.env.VUE_APP_ADMIN_URL + "/api/userData.php"

  // userId.value получен при авторизации
  await axios
      .get(`${urlData}?token=${token.value}&id=${userId.value}`)
      .then(response => (userData.value = response.data))

  // обновить данные о пользователе
  store.commit('userModule/setUserData', userData.value)
  store.commit('menuModule/initMenu')
}

//----------------------------------------------------------------------
const token = computed( () => {return store.getters['authModule/token']})
const userId = computed( () => {return store.getters['authModule/userId']})

</script>
<template>
  <main class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col"><h3>О мероприятии</h3></div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">

            <div class="card card-outline card-info">
              <div class="card-header pt-3">
                <h4>Финал чемпионата России и первенства России по спортивному программированию</h4>
              </div>
              <div class="card-body">

                <b>Этапы:</b>
                <ul>
                  <li>Финал (06.12 - 08.12) — онлайн</li>
                  <li>Суперфинал 15.12 — очный</li>
                </ul>

                <b>Кто выходит в супер финал:</b>
                <br>На чемпионате России и первенстве России в супер финал выходят по 5 команд. Итого 10 по дисциплине продуктовое программирование.

                <br><br><b>Задача:</b>
                <br>Создание платформы для взаимодействия с региональными представителями федерации спортивного программирования.

                <hr>
              </div>
            </div>

            <div class="card card-outline card-success">
              <div class="card-header pt-3">
                <h4>Организаторы</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 col-sm-12 mb-4 text-center">
                    <img src="https://pp.fsp-russia.com/images/logo/min-sport-logo.png" class="img-fluid">
                  </div>
                  <div class="col-md-6 col-sm-12 mb-4 text-center">
                    <img src="https://pp.fsp-russia.com/images/logo/fsp-logo.png" class="img-fluid">
                  </div>
                </div>
              </div>
            </div>

            <div class="card card-outline card-danger">
              <div class="card-header pt-3">
                <h4>Партнеры</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 col-sm-12 mb-4 text-center">
                    <img src="https://pp.fsp-russia.com/images/logo/stoloto-logo.png" class="img-fluid">
                  </div>
                  <div class="col-md-6 col-sm-12 mb-4 text-center">
                    <img src="https://pp.fsp-russia.com/images/logo/uralchem-logo.png" class="img-fluid">
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

  </main>
</template>

<style scoped>
.logo{
  max-width: 350px;
  max-height: 200px;
  -webkit-filter: saturate(0%);
  filter: saturate(0%);
}
</style>