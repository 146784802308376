<script setup>
import {useStore} from "vuex";
import {ref, onMounted, computed} from "vue";
import axios from "axios";
//import {useScrollBottom} from "@/use/scroll-bottom";
import {criteriaList} from "@/use/criteria-list";

//----------------------------------------------------------------------

const store = useStore()
const isSubmitting = ref(false)
const isSubmitComplete = ref(false)
const infoMessage = ref('')

// все критерии отмечены и готовы к отправке
const isCriteriaReady = ref(false)

// список экспортируется в criteriaList
const criteria = ref([])

// полученные данные о сохраненных критериях
const savedCriteria = ref([])

// дата сохранения критериев
const savedCriteriaDate = ref('')

const token = computed( () => {return store.getters['authModule/token']})
//const user = computed( () => {return store.getters['authModule/user']})

//----------------------------------------------------------------------

// eslint-disable-next-line no-undef
const props = defineProps({
  teamId:{
    type: String,
    required: true,
  },
  userId:{
    type: String,
    required: true,
  },
})

//----------------------------------------------------------------------
onMounted( ()=>{
  try {
    init()
  } catch (err) {
    alert ('APPLICATION ERROR')
  }
})
//----------------------------------------------------------------------

const init = async () => {

  criteria.value = criteriaList()
  const url = process.env.VUE_APP_ADMIN_URL + "/api/criteria.php"
  await axios
      .get(`${url}?userId=${props.userId}&teamId=${props.teamId}&token=${token.value}}`)
      .then(response => (savedCriteria.value = response.data))
      .catch(error => alert(error));

      //console.log(savedCriteria.value)

      if (savedCriteria.value['created'] != null){
        // критерии уже сохранены
        // в соотв. со структурой criteriaList + sections

        criteria.value[0]['sections'][0]['activeIndex'] = savedCriteria.value['criteria1'];
        criteria.value[1]['sections'][0]['activeIndex'] = savedCriteria.value['criteria2'];
        criteria.value[2]['sections'][0]['activeIndex'] = savedCriteria.value['criteria3'];
        criteria.value[3]['sections'][0]['activeIndex'] = savedCriteria.value['criteria4'];

        criteria.value[4]['sections'][0]['activeIndex'] = savedCriteria.value['criteria5'];
        criteria.value[4]['sections'][1]['activeIndex'] = savedCriteria.value['criteria6'];
        criteria.value[4]['sections'][2]['activeIndex'] = savedCriteria.value['criteria7'];
        criteria.value[4]['sections'][3]['activeIndex'] = savedCriteria.value['criteria8'];
        criteria.value[4]['sections'][4]['activeIndex'] = savedCriteria.value['criteria9'];
        criteria.value[4]['sections'][5]['activeIndex'] = savedCriteria.value['criteria10'];
        criteria.value[4]['sections'][6]['activeIndex'] = savedCriteria.value['criteria11'];
        criteria.value[4]['sections'][7]['activeIndex'] = savedCriteria.value['criteria12'];
        criteria.value[4]['sections'][8]['activeIndex'] = savedCriteria.value['criteria13'];

        criteria.value[5]['sections'][0]['activeIndex'] = savedCriteria.value['criteria14'];
        criteria.value[5]['sections'][1]['activeIndex'] = savedCriteria.value['criteria15'];
        criteria.value[5]['sections'][2]['activeIndex'] = savedCriteria.value['criteria16'];
        criteria.value[5]['sections'][3]['activeIndex'] = savedCriteria.value['criteria17'];
        criteria.value[5]['sections'][4]['activeIndex'] = savedCriteria.value['criteria18'];
        criteria.value[5]['sections'][5]['activeIndex'] = savedCriteria.value['criteria19'];
        criteria.value[5]['sections'][6]['activeIndex'] = savedCriteria.value['criteria20'];
        criteria.value[5]['sections'][7]['activeIndex'] = savedCriteria.value['criteria21'];
        criteria.value[5]['sections'][8]['activeIndex'] = savedCriteria.value['criteria22'];

        savedCriteriaDate.value = savedCriteria.value['created'];
        infoMessage.value="Для отправки результатов измените значение выбранного критерия"
      } else {
        infoMessage.value="Для отправки результатов укажите значения всех критериев"
      }

      //console.log(criteria.value)

}

//----------------------------------------------------------------------
const setCriteria = (value, index, sectionIndex) => {
    isCriteriaReady.value = true
    criteria.value[index]['sections'][sectionIndex]['activeIndex'] = value;
    criteria.value.forEach(function (item) {
      item['sections'].forEach(function (section) {
        if (section['activeIndex'] == 0) {
          isCriteriaReady.value = false
        }
      })
    });
}
//----------------------------------------------------------------------
// отправка
const onSubmit = async () => {

  try {
    isSubmitting.value = true

    const headers = {
      'Authorization': 'Bearer ' + token.value
    }

    const values = {
      'criteria' : criteria.value,
      'teamId' : props.teamId,
      'userId': props.userId,
    }

    //const payload = {values, ...{'userId': user.value.id}}
    console.log(values)

    const {data} = await axios.post(process.env.VUE_APP_SEND, values, {
      headers: headers
    })

    //console.log(data)

    if (data['status'] === 'SUCCESS'){
      savedCriteriaDate.value = data['date'];
      isSubmitComplete.value = true;
      isSubmitting.value = false;
      isCriteriaReady.value = false;
      //setTimeout(() => useScrollBottom(), 300);
      setTimeout(() => isSubmitComplete.value = false, 3000);
    }

  } catch (e) {
    alert ('INTERNAL_SERVER_ERROR')
  }

}
</script>
<template>
  <div>
    <h3 class="text-center">Критерии оценки</h3>
    <p v-if="savedCriteria['created'] != null" class="text-center">Сохранены {{ savedCriteriaDate }}</p>
    <div class="number mt-4">1</div>
    <div class="v-line"></div>

    <div
        v-for="(item,index) in criteria"
        :key="index"
    >
      <div
          class="card card-outline card-info m-0"
      >
        <div class="card-header"><h4 class="text-center">{{ item['title'] }}</h4></div>
        <div class="card-body pb-5">
          <div
              v-for="(section,sectionIndex) in item['sections']"
              :key="sectionIndex"
          >
            <div :class="['m-auto w-75', {'border-bottom': item['sections'].length > 1 }]">
              <div class="m-4 text-center" v-html="section['name']"></div>
              <div class="d-flex flex-wrap justify-content-center">
                <div
                    v-for="value in section['values']" :key="value"
                    :class="['criteria-counter m-3', {'criteria-counter-selected': section['activeIndex'] == value }]"
                    @click="setCriteria(value, index, sectionIndex)"
                >
                  {{ value - 1 }}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div v-if="index < (criteria.length-1)">
        <div class="v-line"></div>
        <div class="number">{{ index+2 }}</div>
        <div class="v-line"></div>
      </div>
    </div>

    <div class="text-center mt-5 mb-4">

      <div v-if="isSubmitComplete"  class="alert alert-success text-center">
        <v-icon
            class="success-icon--white m-2"
            icon="mdi:check-circle-outline"
        ></v-icon>
        <h4>Оценки отправлены</h4>
      </div>

      <button
          class="btn btn-send"
          @click="onSubmit()"
          :disabled="!isCriteriaReady"
      >
        <svg v-if="isSubmitting && !isSubmitComplete"
             xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g>
          <circle cx="3" cy="12" r="2" fill="#fff"/>
          <circle cx="21" cy="12" r="2" fill="#fff"/>
          <circle cx="12" cy="21" r="2" fill="#fff"/>
          <circle cx="12" cy="3" r="2" fill="#fff"/>
          <circle cx="5.64" cy="5.64" r="2" fill="#fff"/>
          <circle cx="18.36" cy="18.36" r="2" fill="#fff"/>
          <circle cx="5.64" cy="18.36" r="2" fill="#fff"/>
          <circle cx="18.36" cy="5.64" r="2" fill="#fff"/>
          <animateTransform attributeName="transform" dur="1.5s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"/></g>
        </svg>
        <span v-else>Отправить оценки</span>
      </button>

      <div v-if="!isCriteriaReady" class="m-2 text-danger text-bold">
        {{ infoMessage }}
      </div>

    </div>
  </div>
</template>
<style scoped>
.criteria-counter{
  background-color:#eff3f9;
  padding:10px 10px;
  margin:10px 5px 10px 5px;
  font-size:21px;
  font-weight:bold;
  text-align:center;
  cursor:pointer;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  width:52px;
  transition: 0.2s;
}
.criteria-counter-selected{
  background-color:#80b5d3 !important;
  color:#fff;
}
</style>