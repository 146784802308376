export function criteriaList(){
    const criteria =
        [
            {
                id: 1,
                title: 'Архитектура приложения',
                sections:
                    [
                        {
                            id: 1,
                            name: 'Масштабируемость, модульность, структура базы данных',
                            values: 16,
                            activeIndex: 0,
                        },
                    ]
            },
            {
                id: 2,
                title: 'Оценка кода',
                sections:
                    [
                        {
                            id: 2,
                            name: 'Сервер с решением, доступный для просмотра в течении 10 рабочих дней после соревнований, и/или с описанием запуска решения (а так же скриншотами) в файле Readme, и/или файлы формата *.apk',
                            values: 6,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 3,
                title: 'Презентация MVP',
                sections:
                    [
                        {
                            id: 3,
                            name: 'Ясность и структурированность презентации, качество слайдов и визуальных материалов, уверенность и навыки публичного выступления',
                            values: 6,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 4,
                title: 'Структура данных',
                sections:
                    [
                        {
                            id: 4,
                            name: 'Полнота данных, количество соревнований, которые получены через парсинг ЕКП',
                            values: 11,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 5,
                title: 'Тестирование сценариев (готовность решения)',
                sections:
                    [
                        {
                            id: 51,
                            name: '<b>Сценарий 1</b><br>Реализация личного кабинета представителя ФСП по взаимодействию с регионами с возможностью администрирования (роль суперадминистратора) ЛК региональных представителей.',
                            values: 5,
                            activeIndex: 0,
                        },
                        {
                            id: 52,
                            name: '<b>Сценарий 2</b><br>Реализация личного кабинета регионального представителя<br>(с возможностью опубликовать информацию о региональном ФСП).',
                            values: 5,
                            activeIndex: 0,
                        },
                        {
                            id: 53,
                            name: '<b>Сценарий 3</b><br>Реализация функции авторизации (авторизация по логину/паролю, восстановление пароля, подтверждение почты).',
                            values: 5,
                            activeIndex: 0,
                        },
                        {
                            id: 54,
                            name: '<b>Сценарий 4</b><br>Построить процесс отправки заявки о предстоящем соревновании с возможностью внесения в ЕКП от региональных представительств и рассмотрения/принятия/внесения предложения по заявкам, представителем центрального ФСП.',
                            values: 5,
                            activeIndex: 0,
                        },
                        {
                            id: 55,
                            name: '<b>Сценарий 5</b><br>Публикация в общедоступном календаре соревнования от регионального представителя после одобрения центрального ФСП.',
                            values: 5,
                            activeIndex: 0,
                        },
                        {
                            id: 56,
                            name: '<b>Сценарий 6</b><br>Возможность просматривать календарь соревнований от ФСП (с фильтрацией по регионам).',
                            values: 5,
                            activeIndex: 0,
                        },
                        {
                            id: 57,
                            name: '<b>Сценарий 7</b><br>Реализация мультизагрузчика результатов соревнований.',
                            values: 5,
                            activeIndex: 0,
                        },
                        {
                            id: 58,
                            name: '<b>Сценарий 8</b><br>Возможность формирования и просмотра аналитических отчетов по регионам, командам, участникам (исходим из гипотезы, что данные есть и их получаем от региональных представителей).',
                            values: 5,
                            activeIndex: 0,
                        },
                        {
                            id: 59,
                            name: '<b>Дополнительные сценарии (готовность решения)</b>',
                            values: 17,
                            activeIndex: 0,
                        },
                    ]
            },
            {
                id: 6,
                title: 'Тестирование сценариев (UX и UI)',
                sections:
                    [
                        {
                            id: 61,
                            name: '<b>Сценарий 1</b><br>Реализация личного кабинета представителя ФСП по взаимодействию с регионами с возможностью администрирования (роль суперадминистратора) ЛК региональных представителей.',
                            values: 4,
                            activeIndex: 0,
                        },
                        {
                            id: 62,
                            name: '<b>Сценарий 2</b><br>Реализация личного кабинета регионального представителя<br>(с возможностью опубликовать информацию о региональном ФСП).',
                            values: 4,
                            activeIndex: 0,
                        },
                        {
                            id: 63,
                            name: '<b>Сценарий 3</b><br>Реализация функции авторизации (авторизация по логину/паролю, восстановление пароля, подтверждение почты).',
                            values: 4,
                            activeIndex: 0,
                        },
                        {
                            id: 64,
                            name: '<b>Сценарий 4</b><br>Построить процесс отправки заявки о предстоящем соревновании с возможностью внесения в ЕКП от региональных представительств и рассмотрения/принятия/внесения предложения по заявкам, представителем центрального ФСП.',
                            values: 4,
                            activeIndex: 0,
                        },
                        {
                            id: 65,
                            name: '<b>Сценарий 5</b><br>Публикация в общедоступном календаре соревнования от регионального представителя после одобрения центрального ФСП.',
                            values: 4,
                            activeIndex: 0,
                        },
                        {
                            id: 66,
                            name: '<b>Сценарий 6</b><br>Возможность просматривать календарь соревнований от ФСП (с фильтрацией по регионам).',
                            values: 4,
                            activeIndex: 0,
                        },
                        {
                            id: 67,
                            name: '<b>Сценарий 7</b><br>Реализация мультизагрузчика результатов соревнований.',
                            values: 4,
                            activeIndex: 0,
                        },
                        {
                            id: 68,
                            name: '<b>Сценарий 8</b><br>Возможность формирования и просмотра аналитических отчетов по регионам, командам, участникам (исходим из гипотезы, что данные есть и их получаем от региональных представителей).',
                            values: 4,
                            activeIndex: 0,
                        },
                        {
                            id: 69,
                            name: '<b>Дополнительные сценарии (UX и UI)</b>',
                            values: 9,
                            activeIndex: 0,
                        },
                    ]
            },

        ];
    return criteria;

}